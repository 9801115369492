import React from 'react';
import classnames from 'classnames';

import './Container.scss';

const Container = (props) => {
  const { children, className, size } = props;

  const containerSize = size ? `ContainerSize${size}` : '';

  return (
    <div className={classnames('Container', containerSize, className)}>
      {children}
    </div>
  );
};

export default Container;
