import React from "react";
import { graphql, useStaticQuery } from "gatsby"

import PageLayout from "../layouts/Page";
import Container from "../components/Container"

const ContentPage = props => {

  const ContentPageQuery = useStaticQuery(graphql`
    query ContentQuery {
      BlogPosts2023: allSanityBlogPost(sort: {order: DESC, fields: date}, filter: {date: {gt: "2023-01-01"}}) {
        totalCount
        nodes {
          authors {
            name
          }
          title
          tags {
            name
          }
          slug {
            current
          }
        }
      }
      BlogPosts2022: allSanityBlogPost(sort: {order: DESC, fields: date}, filter: {date: {gt: "2022-01-01"}}) {
        totalCount
        nodes {
          authors {
            name
          }
          title
          tags {
            name
          }
          slug {
            current
          }
        }
      }
      BlogPosts2021: allSanityBlogPost(sort: {order: DESC, fields: date}, filter: {date: {lt: "2022-01-01", gt: "2021-01-01"}}) {
        totalCount
        nodes {
          authors {
            name
          }
          title
          tags {
            name
          }
          slug {
            current
          }
        }
      }
      BlogPosts2020: allSanityBlogPost(sort: {order: DESC, fields: date}, filter: {date: {lt: "2021-01-01", gt: "2020-01-01"}}) {
        totalCount
        nodes {
          authors {
            name
          }
          title
          tags {
            name
          }
          slug {
            current
          }
        }
      }
      BlogPosts2019: allSanityBlogPost(sort: {order: DESC, fields: date}, filter: {date: {lt: "2020-01-01", gt: "2019-01-01"}}) {
        totalCount
        nodes {
          authors {
            name
          }
          title
          tags {
            name
          }
          slug {
            current
          }
        }
      }
      allSanityCaseStudy {
        totalCount
        nodes {
          slug {
            current
          }
          company {
            name
          }
        }
      }
      allSanityVideo {
        totalCount
        nodes {
          title
          slug {
            current
          }
          tags {
            slug {
              current
            }
            name
          }
        }
      }
      allSanityEbook {
        totalCount
        nodes {
          slug {
            current
          }
          ebookTitle
        }
      }
      allSanityWebinar {
        totalCount
        nodes {
          webinarTitle
          slug {
            current
          }
        }
      }
    }
  `);

  const { BlogPosts2023, BlogPosts2022, BlogPosts2021, BlogPosts2020, BlogPosts2019, allSanityCaseStudy, allSanityEbook, allSanityVideo, allSanityWebinar } = ContentPageQuery;

  return (
    <PageLayout>
      <Container>
        <details style={{marginTop: "2em"}}>
          <summary><h4 style={{display: "inline-block"}}>Blog posts in 2023 ({BlogPosts2023.totalCount})</h4></summary>
          <ul>
            {BlogPosts2023.nodes.map(post => (
              <li>
                <a href={`/blog/${post.slug.current}`}>{post.title}</a> {post.tags.length > 0 && <>tags: {post.tags.map(tag => tag.name).join(", ")}</>}
              </li>
            ))}
          </ul>
        </details>

        <details>
          <summary><h4 style={{display: "inline-block"}}>Blog posts in 2022 ({BlogPosts2022.totalCount})</h4></summary>
          <ul>
            {BlogPosts2022.nodes.map(post => (
              <li>
                <a href={`/blog/${post.slug.current}`}>{post.title}</a> {post.tags.length > 0 && <>tags: {post.tags.map(tag => tag.name).join(", ")}</>}
              </li>
            ))}
          </ul>
        </details>

        <details>
          <summary><h4 style={{display: "inline-block"}}>Blog posts in 2021 ({BlogPosts2021.totalCount})</h4></summary>
          <ul>
            {BlogPosts2021.nodes.map(post => (
              <li>
                <a href={`/blog/${post.slug.current}`}>{post.title}</a> {post.tags.length > 0 && <>tags: {post.tags.map(tag => tag.name).join(", ")}</>}
              </li>
            ))}
          </ul>
        </details>

        <details>
          <summary><h4 style={{display: "inline-block"}}>Blog posts in 2020 ({BlogPosts2020.totalCount})</h4></summary>
          <ul>
            {BlogPosts2020.nodes.map(post => (
              <li>
                <a href={`/blog/${post.slug.current}`}>{post.title}</a> {post.tags.length > 0 && <>tags: {post.tags.map(tag => tag.name).join(", ")}</>}
              </li>
            ))}
          </ul>
        </details>

        <details>
          <summary><h4 style={{display: "inline-block"}}>Blog posts in 2019 ({BlogPosts2019.totalCount})</h4></summary>
          <ul>
            {BlogPosts2019.nodes.map(post => (
              <li>
                <a href={`/blog/${post.slug.current}`}>{post.title}</a> {post.tags.length > 0 && <>tags: {post.tags.map(tag => tag.name).join(", ")}</>}
              </li>
            ))}
          </ul>
        </details>

        <details>
          <summary><h4 style={{display: "inline-block"}}>Case Studies ({allSanityCaseStudy.totalCount})</h4></summary>
          <ul>
            {allSanityCaseStudy.nodes.map(casestudy => (
              <li>
                <a href={`/customers/${casestudy.slug.current}`}>{casestudy.company.name}</a>
              </li>
            ))}
          </ul>
        </details>
        
        <details>
          <summary><h4 style={{display: "inline-block"}}>Videos ({allSanityVideo.totalCount})</h4></summary>
          <ul>
            {allSanityVideo.nodes.map(video => (
              <li>
                <a href={`/videos/${video.slug?.current}`}>{video.title}</a> {video.tags.length > 0 && <>tags: {video.tags.map(tag => tag.name).join(", ")}</>}
              </li>
            ))}
          </ul>
        </details>

        <details>
          <summary><h4 style={{display: "inline-block"}}>Webinars ({allSanityWebinar.totalCount})</h4></summary>
          <ul>
            {allSanityWebinar.nodes.map(webinar => (
              <li>
                <a href={`/webinars/${webinar.slug.current}`}>{webinar.webinarTitle}</a>
              </li>
            ))}
          </ul>
        </details>
        
        <details>
          <summary><h4 style={{display: "inline-block"}}>Ebooks ({allSanityEbook.totalCount})</h4></summary>
          <ul>
            {allSanityEbook.nodes.map(ebook => (
              <li>
                <a href={`/ebooks/${ebook.slug.current}`}>{ebook.ebookTitle}</a>
              </li>
            ))}
          </ul>
        </details>
      </Container>
    </PageLayout>
  );
};

export default ContentPage;
